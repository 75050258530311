@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.whatApp_cont{
    @include displayCode(center);
    text-decoration: none;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--textPrimary-color);
}

.footer_cont {
    @include displayCode(space-between);
    flex-direction: column;
    width: 100vw;
    height: max-content;
    background-image: url("../../assets/images/footerBackground.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 8rem 2rem 8rem;
    font-family: "Kanit";
    gap: 8rem;

    .ab_cont {
        @include displayCode(center);
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        .footer_header {
            font-size: 3.125rem;
            font-weight: 700;
            color: var(--textPrimary-color);
        }

        .footerCont_cont {
            @include displayCode(center);
            flex-direction: column;
            width: 100%;
            height: max-content;
            gap: 3rem;

            .footer_content {
                text-align: center;
                font-size: 1.5rem;
                font-weight: 300;
                color: var(--textPrimary-color);
            }

            .contact_cont {
                @include displayCode(center);
                align-items:center;
                flex-direction: column;
                width: 100%;
                height: max-content;


                .contact_subCont {
                    @include displayCode(center);
                    flex-direction: column;
                    gap:1rem;

                    .contact_abCont {
                        @include displayCode(center);
                        gap: 1rem;

                        .email_cont,
                        .phno_cont,.whatApp_cont {
                            @include displayCode(flex-start);
                            gap: 0.5rem;
                            font-size: 1.5rem;
                            font-weight: 600;
                            color: var(--textPrimary-color);
                        }

                    }


                    .dateTime {
                        font-size: 1.25rem;
                        font-weight: 300;
                        color: var(--textPrimary-color);
                    }
                }
            }

            .address_cont {
                @include displayCode(center);
                flex-direction: column;

                .c_name {
                    @include displayCode(center);
                }

                .add {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #F0AB2C;
                    text-transform: uppercase;
                }

                .addContent {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: var(--textPrimary-color);
                }
            }

            .footer_fieldsCont {
                display: grid;
                grid-template-columns: repeat(4,1fr);
                align-items: start !important;
                width: 100%;
                height: max-content;
                padding: 2rem 0;
                gap:0.5rem;

                .footer_fieldSubCont {
                    @include displayCode(start);
                    align-items: start !important;
                    justify-content: start !important;
                    flex-direction: column;
                    flex-basis: 25%;
                    gap: 0.8rem;

                     .ffield_header{
                        color:var(--textSecondary-color);
                        font-weight:bold;
                        padding:0 !important;
                     }

                    .footer_field {
                        @include displayCode(start);
                        align-items: start !important;
                        flex-direction: column;
                        list-style: none;
                        gap: 0.5rem;

                        li{
                            font-size:0.8125rem;

                            a{
                                color:var(--textPrimary-color);
                                font-size:1rem !important;
                                text-decoration: none;
                            }
                        }
                    }
                }

                .SecurityCer_cont {
                    @include displayCode(start);
                    align-items:start !important;
                    justify-content: start !important;
                    flex-direction: column;
                    width: max-content;
                    height: max-content;
                    gap:0.8rem;
                    padding-top:30px;

                    .ffield_header{
                        color:var(--textPrimary-color);
                        font-weight:bold;
                     }

                    .SecurityCer_fieldCont {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 0.5rem;
                        grid-row-gap:0.2rem;

                        .fImg_cont {
                            width: max-content;
                            height: max-content;

                            .FImg {
                                width: 6rem;
                                height: 2.875rem;
                                border-radius: 0.5rem;
                            }
                        }

                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        @include displayCode(space-between);
        font-size: 1.5rem;
        font-weight: 300;
        color: var(--textPrimary-color);
        text-align: center;

        span {

            a {
                color: var(--textPrimary-color);
                text-decoration: none;

                &:hover {
                    color: var(--textPrimary-color);
                }
            }
        }
    }

}

@media (max-width:1300px) {
    .footer_cont {
        padding: 4rem 4rem 2rem 4rem !important;
    }

    .footer_content {
        font-size: 1.2rem !important;
    }

    .email_cont,
    .phno_cont,.whatApp_cont {
        font-size: 1.2rem !important;
        font-weight: 500 !important;
    }

    .address_cont {

        .add {
            font-size: 1.2rem !important;
            font-weight: 500 !important;
        }

        .addContent {
            font-size: 1.2rem !important;
        }
    }

    .footer {
        width: 100%;
        @include displayCode(space-between);
        font-size: 1.2rem !important;
    }

    .contact_abCont {
        flex-wrap: wrap;
    }
}

@media (max-width:1000px) {
    .contact_cont {
        flex-direction: column !important;
        gap: 2rem !important;
    }

    .contact_subCont {
        @include displayCode(center);
        flex-direction: column;
        gap:0.5rem !important;

        .dateTime {
            font-size: 1rem !important; 
        }
    }

    .address_cont {
        width: 100%;
        @include displayCode(flex-start);
        align-items: center !important;

        .add {
            text-align: center;
        }

        .addContent {
            text-align: center;
        }
    }

    .c_name {
        justify-content: center !important;
    }

    .footer {
        width: 100%;
        @include displayCode(space-between);
        flex-direction: column !important;
        font-size: 1.2rem !important;
    }
}

@media (max-width:900px) {
    .footer_fieldsCont {
        @include displayCode(center);
        flex-direction: column;
        gap:2rem !important;
        
    }

    .footer_fieldsCont {
        grid-template-columns: repeat(2,1fr) !important;
        padding:0 0 2rem 0 !important;
    }

    
}

@media (max-width:600px) {
    

    .SecurityCer_cont {
        padding-top:0px !important;
    }

    .footer_cont {
        padding: 2rem !important;
    }

    .footer_header {
        font-size: 2rem !important;
    }

    .footer_content {
        font-size: 1rem !important;
    }

    .email_cont,
    .phno_cont,.whatApp_cont {
        font-size: 1rem !important;
        font-weight: 500 !important;
        gap: 0.2rem !important;

        img {
            width: 1.25rem !important;
            height: 1.25rem !important;
        }

        span {
            font-size: 1rem !important;
        }
    }

    .address_cont {

        .add {
            font-size: 1.2rem !important;
            font-weight: 500 !important;
        }

        .addContent {
            font-size: 1rem !important;
        }
    }

    .footer {
        font-size: 1rem !important;

        span {
            font-size: 1rem !important;
        }
    }

    .dateTime {
        span {
            font-size: 1rem !important;
        }
    }

    .footer_fieldsCont {
        grid-template-columns: repeat(1,1fr) !important;
        padding:0 0 2rem 0 !important;
    }

    .footer_field {

        li{

            a{
                font-size:0.8125rem !important;
            }
        }
    }

    .contact_abCont {
        width: 100%;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column !important;
        gap: 0.5rem !important;
    }
}

@media (max-width:430px) {
    .footer_cont {
        padding: 1rem !important;
        gap: 4rem !important;
    }

    .contact_cont {
        align-items: start !important;
        gap: 2rem !important;
    }
    .contact_abCont {
        width: 100%;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column !important;
        gap: 0rem !important;
    }

    .dateTime {
        width: 100%;
        @include displayCode(flex-start);
        margin-left: 0 !important;

        span {
            font-size: 1rem !important;
        }
    }

    .address_cont {
        width: 100%;
        @include displayCode(flex-start);
        align-items: flex-start !important;
        justify-content: flex-start !important;

        .addContent {
            text-align: start !important;
        }
    }

    .footer_content {
        text-align: justify !important;
    }

    .footer {
        font-size: 0.6rem !important;

        span {
            font-size: 0.6rem !important;
        }
    }

    .email_cont,
    .phno_cont,.whatApp_cont {
        font-size: 1rem !important;
        font-weight: 500 !important;
        gap: 0.2rem !important;

        img {
            width: 1.25rem !important;
            height: 1.25rem !important;
        }

        span {
            font-size: 1rem !important;
        }
    }

    .c_name {
        justify-content: start !important;
    }
}