@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}


.prod_cont {
    width: 100vw;
    height: max-content;
    padding: 8rem 0 4rem 0;
    color:var(--textPrimary-color);
    font-family: "Kanit";
    background-color: #120C03;

    .prod_header {
        @include displayCode(center);
        width: 100%;
        font-family: "Kanit";
        font-weight: 700;
        color: var(--textPrimary-color);
        font-size: 3.125rem;
        padding-bottom:1rem;
    }

    .prod_subCont {
        @include displayCode(center);
        align-items:flex-start;
        width: 100%;

        .vRummyImg_cont1{
            background-image:url("../../assets/images/productbgImg1.webp");
            background-repeat: no-repeat;
            background-size: cover;
        }

        .vLudoImg_cont1{
            background:url("../../assets/images/productbgImg2.webp");
            background-repeat: no-repeat;
            background-size: cover;
        }

        .vRummyImg_cont,
        .vLudoImg_cont {
            flex-basis: 50%;
            @include displayCode(flex-end);
            flex-direction: column;

            .vRummyImg_subcont,.vLudoImg_subCont{
                @include displayCode(center);
                flex-direction: column;
                width:100%;
                .img_header {
                    font-size: 2.5rem;
                    font-weight: 700;
                    text-transform: uppercase; 
                }

                img{
                    height:39.875rem;
                }
            }

            .vRummyCont_cont,
                .vLudoCont_cont {
                    @include displayCode(flex-start);
                    flex-direction: column;
                    flex-basis: 50%;
                    gap:1rem;
                    padding-top:1rem;
                    
    
                    .download_btn {
                        @include displayCode(center);
                        background: linear-gradient(#FEDE55, #825401);
                        padding: 0.5rem 1rem;
                        border-radius: 0.5rem;
                        gap: 1.5rem;
    
                        .inner_download_btn {
                            @include displayCode(center);
                            color: #111;
                            font-weight: 600;
                            font-size: 0.9375rem;
                        }
    
                        .img_cont {
                            @include displayCode(center);
                            gap: 0.5rem;
    
                            .applink{
                                @include displayCode(center);
                                text-decoration: none;

                                .d_img {
                                    width: 3.04125rem;
                                    height: 3.04125rem;
                                }
                            }
                            
                        }
                    }
    
                    .vRummy_content,.vLudo_content{
                        text-align: center;
                        padding:0 5rem;
                    }
                }

            .vRummyCont_cont,
            .vLudoCont_cont {
                @include displayCode(flex-start);
                flex-direction: column;
                flex-basis: 50%;
                gap:1rem;

                .download_btn {
                    @include displayCode(center);
                    background: linear-gradient(#FEDE55, #825401);
                    padding: 0.5rem 1rem;
                    border-radius: 0.5rem;
                    gap: 1.5rem;

                    .inner_download_btn {
                        @include displayCode(center);
                        color: #111;
                        font-weight: 600;
                        font-size: 0.9375rem;
                    }

                    .img_cont {
                        @include displayCode(center);
                        gap: 0.5rem;


                        .d_img {
                            width: 3.04125rem;
                            height: 3.04125rem;
                        }
                    }
                }

                .vRummy_content,.vLudo_content{
                    text-align: center;
                    padding:0 5rem;
                }
            }
            

            
        }

    }

    .prodContent_cont {
        width: 100%;
        height: max-content;
        background: #120C03;

        .prodContent_subCont {
            @include displayCode(center);
            align-items: start;
            width: 100%;
            height: max-content;

            
        }

        .notifi_cont{
            @include displayCode(center);
            width:100%;
            height:max-content;
            padding:2rem 0 0 0;

            .notifi{
                font-size:1.5rem;
                font-weight:700;
                background:#CDB76A;
                border-radius:0.5rem;
                color:#111;
                padding:0.3rem 1rem;
            }
        }
    }
}


@media (max-width:1200px) {
    .prod_subCont {
        flex-direction: column !important;
        gap: 5rem !important;
    }

    .vRummyImg_subcont,.vLudoImg_subCont{
        img{
            width:20rem !important;
            height:30rem !important;
        }
    }
}

@media (max-width:600px) {
    .prod_cont {
        padding:4rem 0 2rem 0 !important
    };

    .prod_header{
        font-size:2rem !important;
    }

    .img_header{
        font-size:1.5rem !important;
        padding:1rem 0 0.5rem 0 !important;
    }

    .notifi_cont{
        padding:1.5rem 2rem 0 2rem !important;

        .notifi{
            font-size:1rem !important;
        }
    }

    .vRummy_content,.vLudo_content{
        padding:0 2rem !important;
    }

    .vRummyImg_cont1{
        background-image:url("../../assets/images/mobilebg1.webp") !important;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .vLudoImg_cont1{
        background:url("../../assets/images/mobilebg2.webp") !important;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

@media (max-width:400px) {
    .prod_cont {
        padding:4rem 0 1rem 0!important
    };

    .notifi_cont{
        padding:1.5rem 1rem 0 1rem !important;
    }

    .vRummyImg_subcont,.vLudoImg_subCont{
        img{
            width:15rem !important;
            height:23rem !important;
        }
    }
}
