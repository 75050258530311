*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --textPrimary-color:#fff;
  --textSecondary-color:#FFC148;
}

@font-face {
  font-family: "Maven Pro";
  src: url('./assets/fonts/MavenPro-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap; 
}

@font-face {
  font-family: "Kanit";
  src: url('./assets/fonts/Kanit-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}



::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.alice-carousel__dots{
  position: absolute;
  bottom:1rem;
  width:100%;
  display: flex;
  justify-content: center;
  margin: 0 !important;
  padding:0 !important;
}

.alice-carousel__dots-item:not(.__custom){
  width:29.12px !important;
  height:29.12px !important;
  background:#a6959028 !important;
}

.alice-carousel__dots-item:not(.__custom).__active {
 background:var(--textSecondary-color) !important; 
 border:4px solid var(--textPrimary-color);
}

.up_btncont{
  width:max-content;
  height:max-content;
  position: fixed;
  bottom:2rem;
  right:1rem;
  z-index:10000;
}

.up_btncont:hover{
  cursor: pointer;
}

.up_btncontnone{
  display: none ;
}

.alice-carousel__wrapper{
  height:100% !important;
  object-fit: fill;
}

 @media (max-width:1000px) {
  .alice-carousel__dots-item:not(.__custom){
    width:15.12px !important;
    height:15.12px !important;
  }

  .alice-carousel__dots-item:not(.__custom).__active {
    background:var(--textSecondary-color) !important; 
    border:1px solid var(--textPrimary-color) !important;
   }
}

@media (max-width:600px) {
  .alice-carousel__dots-item:not(.__custom){
    width:10.12px !important;
    height:10.12px !important;
  }

  .alice-carousel__dots-item:not(.__custom).__active {
    background:var(--textSecondary-color) !important; 
    border:0.875px solid var(--textPrimary-color) !important;
   }
}

.MuiMenu-list{
  background:#000 !important;
  border: none !important;
}

@media (max-width:600px) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    padding:1rem 1rem 0rem 1rem !important;
  }
}