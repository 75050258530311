@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.exp_cont{
    width:100vw;
    height:100vh;
    background-image: url("../../assets/images/expBackground.webp");
    background-repeat: no-repeat;
    background-size: cover;

    .exp_subCont{
        width:100%;
        height:100%;
        padding:0 4rem;
        background-color: rgba(0,0,0,.568);

        .expContent_cont{
            @include displayCode(center);
            flex-direction: column;
            width: 50%;
            height:100%;
            float: left;
    
            .exp_header{
                font-size:3.125rem;
                font-weight: 700;
                color: var(--textPrimary-color);
                font-family:"Kanit";
            }
    
            .expText_cont{
                @include displayCode(center);
                align-items: flex-start !important;
                flex-direction: column;
                width:100%;
                height:max-content;
                font-family:"Kanit";
                gap:1.5rem;
    
                .exp_text{
                   font-size: 1.25rem; 
                   font-weight:400;
                   font-family:"Kanit";
                   color:var(--textPrimary-color);
                }
    
                .exp{
                    font-weight:600;
    
                    span{
                        color:var(--textSecondary-color);
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    
}

@media (max-width:1000px) {
    .expContent_cont{
        width:100% !important;
        text-align: justify;
    }
}

@media (max-width:600px) {
    .exp_cont{
        height:max-content !important;
    }

    .exp_subCont{
        padding:4rem 2rem 2rem 2rem !important;
    }

    .expText_cont{
        padding-top:1rem !important;
    }
    
    .expContent_cont{
        width:100% !important;
        text-align: justify;
        float:none !important;
    }

    .exp_header{
        font-size:2rem !important;
    }
}

@media (max-width:400px) {
    .exp_text{
        font-size:1rem !important;
    }

    .exp_cont{
        height:max-content !important;
    }

    .exp_subCont{
        padding:4rem 1rem 1rem 1rem!important;
    }
}

