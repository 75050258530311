@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.aboutGame_cont {
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    opacity: 0;

    &.aboutGame_cont2 {
        animation-name: slideInLeft;  // Rummy sliding in from the left
    }

    &.aboutGame_cont1 {
        animation-name: slideInRight;  // Ludo sliding in from the right
    }
}

.abtn_cont {
    display: flex;
    justify-content: space-around;
}

.rummyImg_cont,
.ludoImg_cont {
    cursor: pointer;
}

.home_cont {
    width: 100vw;
    height:max-content;
    z-index: 1000;

    .model {
        height: 100%;
        position: absolute;
        right: 0;
    }

    .header_cont {
        @include displayCode(space-between);
        width: 100vw;
        height: max-content;
        padding: 1.5rem 4rem 1rem 4rem;
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        left:0;
        z-index:1000;

        .logo_cont {
                @include displayCode(center);
        
                .logo_img {
                    display: none;
                    width: 10rem;
                    height:6.34rem;
                }
            }

        .nav_cont {
            @include displayCode(center);
            gap: 2.5rem;

            .link_lists {
                @include displayCode(center);
                list-style: none;
                gap: 1.5rem;
                font-family: "Kanit";

                .list {
                    color: var(--textPrimary-color);
                    font-size: 1.25rem;
                    font-weight: 500;

                    .listLink{
                        color:var(--textPrimary-color);
                        text-decoration: none;

//                         background: linear-gradient(90deg, #808080,#808080 );
//   -webkit-background-clip: text; 
//   -webkit-text-fill-color: transparent; 
  font-weight: 600;

                        &:hover {
                            color:var(--textSecondary-color);
                            background: linear-gradient(90deg, #FFC148,#FFC148 );
  -webkit-background-clip: text; 
                        }

                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .contact_options {
                @include displayCode(center);
                gap: 1rem;

                &:hover {
                    cursor: pointer;
                }

                .contact_optionsImg {
                    @include displayCode(center);
                }
            }

            .download_btn{
                @include displayCode(center);
                padding:0.5rem 0.5rem;
                background:var(--textSecondary-color);
                border-radius:0.5rem;
                font-family: "Kanit";
                font-weight:600;
                text-align: center;
                letter-spacing:1px;
                display: none;

                .login_link1{
                    color:#111;
                    text-decoration: none;
                }
            }

            .downloadApp{
                @include displayCode(center);
                padding:0.5rem 1rem;
                background:var(--textSecondary-color);
                border-radius:0.5rem;
                font-family: "Kanit";
                font-weight:600;
                text-align: center;
                letter-spacing:1px;

                .login_link{
                    text-decoration: none;
                    color:#111;
                }
            }
        }
    }

    .banner_cont {
        width: 100%;
        height:100%;

        .tagline {
            width: max-content;
            height: max-content;

            .tagline_content {
                font-size: 4.6875rem;
                font-weight: 700;
                font-family: "Kanit";
                text-transform: uppercase;
                color: var(--textPrimary-color);
                line-height: 5.5rem;
            }

            .tagline_text {
                font-size: 2.25rem;
                font-weight: 500;
                color: var(--textSecondary-color);
            }
        }
    }
}

.main_cont{
    // @include displayCode(center);
    display: flex;
    width:100vw;
    height:max-content;
    overflow-x: hidden;
    background: #000;


.aboutGame_cont {
    @include displayCode(space-between);
    flex-direction: column;
    width: 100vw;
    height:max-content;
    background: #000;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 4rem 2rem 4rem;
    gap:5rem;
    opacity:1;
    transition: opacity 0.3s ease;
    

    .abGame_subCont {
        @include displayCode(center);
        width: 100%;
        height: max-content;
        padding-top:10rem;

        .vyka_rummyImgCont {
            @include displayCode(center);
            width: 50%;

            .vykaRummy_img{
                width: 100% !important;
                height:max-content;
                object-fit: fill;
                display: none;
            }

            .vykaRummy_img1{
                width: 100% !important;
                height:max-content;
                object-fit: fill;
            }

            .vykaRummy_img1 {
                width:28.125rem;
                height:26.8125rem;
            }
        }

        .aboutgame_text {

            span {
                color: var(--textSecondary-color);
            }

            width:50%;
            font-size: 2.1875rem;
            font-weight:700;
            font-family:"Kanit";
            color: var(--textPrimary-color);
        }
    }

    .abtn_cont{
        @include displayCode(center);
        gap:2.5rem;
        height:6rem !important;
        // padding:2rem !important;
        background:#000;

        .rummyImg_cont,.ludoImg_cont{
            @include displayCode(center);
            height:100%;
            flex-direction: column;
            cursor: pointer;
            padding:0.5rem 0;

            .rummyImg{
                width:4.5625rem ;
                height:4.5625rem ;
            }

            .activeLudo{
                width:4.5625rem ;
                height:4.5625rem ;
            }

            .ludoImg{
                width:3.5625rem;
                height:3.5625rem;
            }

            .inactiveRummyImg{
                width:3.5625rem;
                height:3.5625rem;
            }
        }
    }
}

}
.abtn_cont{
    @include displayCode(center);
    gap:2.5rem;
    height:6rem !important;
    // padding:2rem !important;
    background:#000;

    .rummyImg_cont,.ludoImg_cont{
        @include displayCode(center);
        height:100%;
        flex-direction: column;
        cursor: pointer;
        padding:0.5rem 0;

        .rummyImg{
            width:4.5625rem ;
            height:4.5625rem ;
        }

        .activeLudo{
            width:4.5625rem ;
            height:4.5625rem ;
        }

        .ludoImg{
            width:3.5625rem;
            height:3.5625rem;
        }

        .inactiveRummyImg{
            width:3.5625rem;
            height:3.5625rem;
        }
    }
}

.header_cont1{
    background-color:#000 ;
}


@media (max-width:1300px) {
    .ludoBoard_img {
       width:34.1875rem !important;
       height:33.625rem !important;
    }

    .nav_cont {
        gap: 1.5rem !important;
    }

    .logo_cont {
        @include displayCode(center);
        .logo_img {
            width: 10rem !important;
            height:5.34rem !important;
        }
    }

    .link_lists {
        gap: 1rem !important;

        .list {
            font-size: 1rem !important;
        }
    }

    .contact_optionsImg {
        .c_img {
            width: 1.875rem !important;
            height: 1.875rem !important;
        }
    }

    .aboutgame_text {
        font-size: 1.5rem !important;
    }



    .downloadApp{
        padding:0.5rem 1rem !important;
    }
}

@media (max-width:1100px) {

    .abGame_subCont {
        @include displayCode(center);
        width: 100%;
        height: max-content;
        padding-top:2rem !important;
    }

    .logo_cont {
        .logo_img {
            width:6rem !important;
            height:4.34rem !important;
        }
    }

    .link_lists {
        gap: 1rem !important;

        .list {
            font-size: 0.75rem !important;
        }
    }

    .contact_optionsImg {
        .c_img {
            width: 1.675rem !important;
            height: 1.675rem !important;
        }
    }

    .aboutGame_cont{
        gap:2rem !important;
    }

    .abGame_subCont {
        flex-direction: column;
        gap:2rem !important;


        .vyka_rummyImgCont {
            width: 100% !important;
        }

        .aboutgame_text {
            width:100% !important;
            color: var(--textPrimary-color);
            text-align:center;
        }
    }


        .abtn_cont {
            @include displayCode(center);
            gap: 2.5rem;
        }

        .header_cont {
            padding: 1.5rem 1rem 1rem 1rem !important;
        }
}

@media (max-width:850px) {
    .logo_cont {
        .logo_img {
            width:4rem !important;
            height:3.34rem !important;
        }
    }

    .link_lists {
        gap: 1rem !important;

        .list {
            font-size:0.625rem !important;
        }
    }

    .contact_options{
        display: none !important;
    }
   
    .nav_cont {
        gap: 0.8rem !important;
    }

    .download_btn{
        padding:0.5rem 0.8rem !important;
        font-size:0.675rem !important;
    }

    .downloadApp{
        padding:0.5rem 0.8rem !important;
        font-size:0.675rem !important;
    }

}

@media (max-width:700px) {
    .vykaRummy_img1 {
        width:26.68rem !important;
        height:15.25rem !important;
    }

    .logo_cont {
        .logo_img {
                width:3rem !important;
                height:2.34rem !important;
        }
    }

    .link_lists {
        gap:0.5rem !important;

        .list {
            font-size:0.5rem !important;
        }
    }

    .header_cont {
        padding: 0.5rem 1rem 0.5rem 1rem !important;
    }

    .ludoBoard_img {
        width:34.1875rem !important;
        height:17.875rem !important;
     }
}

@media (max-width:600px) {
    .vykaRummy_img {
        width: 100% !important;
        object-fit: fill;
        display: block !important;
    }

    .vykaRummy_img1 {
        display: none !important;
    }

    .header_cont1{
        background-color:#000000 !important;
    }

    .link_lists{
        display: none !important;
    }

    .aboutGame_cont{
        padding: 0 2rem 1rem 2rem !important;
    }

    .download_btn{
        display: block !important;
        position: absolute;
        border-radius:0.3rem !important;
        right:7.5rem;
        padding:0.3rem 0.6rem !important;
        font-size:0.65rem !important;

        .login_link1{
            @include displayCode(center);
            gap:0rem;

            .dText{
                line-height: 0;
                font-size:0.65rem !important;
            }    
        }
    }

    .downloadApp{
        position: absolute;
        border-radius:0.3rem !important;
        right:3.5rem;
        padding:0.3rem 0.6rem !important;
        font-size:0.65rem !important;
    }

    .logo_img1{
        display: none !important;
    }

    .logo_img{
        display: block !important;

    }

    .header_cont{
        padding: 0.5rem 1rem 0.5rem 1rem !important;
    }
}

@media (max-width:400px) {

    .dText{
       display: none !important;
    } 

    .download_btn{
        padding:0.45rem 0.5rem !important;
    }

    .aboutGame_cont{
        padding: 0 1rem 1rem 1rem !important;
    }

    .link_lists {
        gap:0.3rem !important;

        .list {
            font-size:0.4rem !important;
        }
    }

    .aboutgame_text {

        span {
            color: var(--textSecondary-color);
            font-size:1rem !important;
        }
        font-size:1rem !important;
        font-weight:700;
        font-family:"Kanit";
        color: var(--textPrimary-color);
    }

        .hamburger{
            font-size:1rem !important;
        }
}

.navLink{
    color:var(--textPrimary-color);
    text-decoration: none;
}

@media (min-width:600px) {
    .hamburger{
        display: none !important;
    }
}

.hamburger{
    @include displayCode(center);
    color:var(--textPrimary-color);
    font-size:1.5rem;
    position: absolute;
    right:0.1rem;
    top:0.5rem;
}


.listLink{
        color:var(--textPrimary-color);
        text-decoration: none;
        font-family: "Kanit";

        &:hover {
            color:var(--textSecondary-color);
        }
    
    }

    .aboutGame_cont1 {
        animation: example 0.5s forwards,hideContainer 1s forwards;
        
      }
      
      @keyframes example {
        0% {
            transform: translateX(-100vw);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        
      }

      .aboutGame_cont2 {
        animation-name: example1;
        animation-duration:0.5s;
      }
      
      @keyframes example1 {
        0% {
            transform: translateX(-100vw);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
      }