@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.abUs_cont {
    @include displayCode(flex-start);
    flex-direction: column;
    width: 100vw;
    height:100vh;
    background-image:url("../../assets/images/background1.webp"),url("../../assets/images/background2.webp") ,url("../../assets/images/background3.webp"),url("../../assets/images/background4.webp"),url("../../assets/images/background5.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding:8rem 4rem 4rem 4rem;
    gap:4rem;

    .abUsContent_cont {
        @include displayCode(center);
        flex-direction: column;
        width: 100%;
        height: max-content;
        gap: 1rem;
        font-family: "Kanit";

        .ab_header {
            font-size: 3.125rem;
            font-weight: 700;
            color: var(--textPrimary-color);
        }

        .intro {
            max-width: 100%;
            width: 1100px;
            font-size: 1.25rem;
            font-weight: 400;
            color: var(--textPrimary-color);
            text-align: center;
        }
    }

    .abComp_cont {
        @include displayCode(center);
        width: 100%;
        height: max-content;
        gap: 3rem;

        .directors_cont {
            @include displayCode(center);
            align-items: flex-start;
            gap: 1.5rem;
            height: max-content;

            .direc_img {

                img {
                    border-radius: 1rem;
                }
            }

            .ab_direc {
                @include displayCode(start);
                align-items: flex-start !important;
                flex-direction: column;

                .direc_name{
                    font-size:1.4375rem;
                    font-weight: 600;
                    font-family: "Kanit";
                    color:var(--textPrimary-color);
                }

                .direc_details{
                    max-width:100%;
                    width:26.5rem;
                    font-size: 1.125rem;
                    font-weight:300;
                    font-family: "Kanit";
                    color:var(--textPrimary-color);
                }
            }
        }
    }

    .custExp_cont{
        @include displayCode(center);
        flex-direction: column;
        width:100%;
        gap:1rem;

        .custExp_header{
            font-size:1.875rem;
            font-weight:700;
            font-family:"Kanit";
            color: var(--textSecondary-color);
            text-transform: uppercase;
        }

        .cust_content{
            @include displayCode(center);
            flex-direction: column;
            font-size:1.25rem;
            font-weight:500;
            font-family: "Kanit";
            text-align: center;
            color:var(--textPrimary-color);
            gap:0.5rem;
        }
    }
}

@media (max-width:1700px) {
    .abComp_cont{
        flex-direction: column !important;
    }

    .direc_details{
        max-width:100%;
        width:30rem !important;
    }
}

@media (max-width:1000px) {

    .abUs_cont{
        height:max-content !important;
    }

    .directors_cont{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column;
    }

    .direc_details{
        max-width:100%;
        width:100% !important;
        text-align: justify;
    }
}

@media (max-width:600px) {

    .abUs_cont {
        padding:4rem 2rem 2rem 2rem !important;
    }
    
    .ab_header{
        font-size:2rem !important;
    }

    .custExp_header{
        text-align: center;
        font-size:1.4rem !important;
    }
}

@media (max-width:400px) {

    .abUs_cont {
        padding:4rem 1rem 1rem 1rem  !important;
    }

    .direc_name{
        font-size:1.25rem !important;
    }

    .intro,.direc_details,.cust_content{
        font-size:1rem !important;
    }

}