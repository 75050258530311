@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.ourM_cont {
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/images/ourMission.webp");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    

    .sub_container{
        width:100%;
        height:100%;
        padding:8rem 4rem 4rem 4rem;
        background-color: rgba(0,0,0,.568);

        .header {
            @include displayCode(center);
            width: 100%;
            font-size: 2.8125rem;
            font-weight: 700;
            font-family: "Kanit";
            color: var(--textPrimary-color);
    
            span {
                color: var(--textSecondary-color);
                font-size: 2.8125rem;
                font-weight: 700;
                font-family: "Kanit";
    
            }
        }
    
        .OMContent_cont {
            width: 100%;
            height: max-content;
            @include displayCode(center);
            flex-direction: column;
            gap: 2rem;
            padding-top: 1rem;
    
            .OMContent {
                font-size: 1.25rem;
                font-weight: 400;
                font-family: "Kanit";
                text-align: center;
                color: var(--textPrimary-color);
            }
        }
    }

    
}

@media (max-width:600px) {
    .ourM_cont {
        height: max-content !important; 
    }

    .sub_container{
        padding: 4rem 2rem !important;
    }

    .header {
        font-size: 2rem !important;

        span {
            font-size: 2rem !important;
        }
    }
}

@media (max-width:400px) {
    .OMContent {
        font-size: 1rem !important;
    }

    .ourM_cont {
        height: max-content !important;
    }

    .sub_container{
        padding: 4rem 1rem !important;
    }
}