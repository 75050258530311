@mixin displayCode($para1) {
    display: flex;
    justify-content: $para1;
    align-items: center;
}

.work_cont{
    @include displayCode(center);
    width:100vw;
    height:100vh;
    background-image:url("../../assets/images/workWithUsBackground.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding:6rem 4rem 4rem 4rem;
    background-color: black;

    .work_subCont{
        width:100%;
        @include displayCode(center);
        font-family: "Kanit";

        .cards_cont{
            flex-basis:50%; 

            img{
                width:100%;
            }
        }

        .wContent_cont{
            display:flex;
            flex-direction: column;
            align-items: flex-start !important;
            flex-basis: 50%;
            gap:1.5rem;

            .w_header{
                font-size:3.125rem;
                font-weight: 700;
                color: var(--textPrimary-color);
            }

            .wText_cont{
                @include displayCode(center);
                flex-direction: column;
                font-size:1.25rem;
                font-weight: 400;
                color:var(--textPrimary-color);
                gap:1rem;
            }

            .notify{
                font-size: 1.5rem;
                font-style: italic;
                font-weight: 300;
                color: var(--textSecondary-color);
            }
        }
    }
}

@media (max-width:1400px) {
    .work_cont{
        height:max-content !important;
    }

    .work_subCont{
        flex-direction: column !important;
    }
}

@media (max-width:600px) {
    .work_cont{
        padding:4rem 2rem 2rem 2rem !important;
    }

    .w_header{
        font-size:2rem !important;
    }
}

@media (max-width:400px) {
    .work_cont{
        padding:4rem 1rem 1rem 1rem !important;
    }

    .wText_cont{
        font-size:1rem !important;
    }

    .notify{
        font-size: 1.25rem !important;
    }
}